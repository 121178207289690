<template>
  <section>
    <div
      id="addQuestionDrawer"
      class="offcanvas offcanvas-end"
      tabindex="-1"
      aria-labelledby="addQuestionDrawerLabel"
    >

      <b-card
        title="Add Question"
        class="pb-auto"
      >
        <validation-observer
          ref="addQuestionForm"
          #default="{invalid, dirty}"
        >
          <b-form
            class="form-container"
            @submit.prevent="onSubmit"
          >
            <b-row>
              <b-col>
                <!-- question text -->
                <b-form-group
                  label="Question Text"
                  label-for="questionText"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Question Text"
                    rules="required"
                    vid="questionText"
                  >
                    <b-form-input
                      id="questionText"
                      v-model="questionForm.text"
                      type="text"
                      class="form-control"
                      placeholder="Question Text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="4"
                md="6"
              >
                <!-- open date -->
                <b-form-group
                  label="Open Date"
                  label-for="openDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Open Date"
                    rules="required"
                    vid="openDate"
                  >
                    <flat-pickr
                      id="openDate"
                      v-model="questionForm.open_date"
                      name="openDate"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- cutoff date -->
                <b-form-group
                  label="Cutoff Date"
                  label-for="cutoffDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cutoff Date"
                    rules="required"
                    vid="cutoffDate"
                  >
                    <flat-pickr
                      id="cutoffDate"
                      v-model="questionForm.cutoff_date"
                      name="cutoffDate"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- close date -->
                <b-form-group
                  label="Close Date"
                  label-for="closeDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Close Date"
                    rules="required"
                    vid="closeDate"
                  >
                    <flat-pickr
                      id="closeDate"
                      v-model="questionForm.close_date"
                      name="closeDate"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mt-2 text-right"
              >
                <!-- reset button -->
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mr-1"
                >
                  Reset
                </b-button>

                <!-- action button -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid || !dirty"
                >
                  Submit Question
                </b-button>
              </b-col>
            </b-row>

          </b-form>
        </validation-observer>
      </b-card>
    </div>

  </section>
</template>

<script>
import {
  BRow, BCol, BButton,
  BForm, BFormGroup, BFormInput,
  BCard,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

function convertToUTC(dateStr) {
  // convert date to UTC
  const localDate = moment(dateStr).local()
  const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
  return convertedDate
}

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,

    flatPickr,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      message: '',
      questionForm: {},
      questionAnswer: null,
      questionAnswerOptions: [
        { text: 'True', value: true },
        { text: 'False', value: false },
      ],

      // validation
      required,
    }
  },
  methods: {
    onSubmit() {
      const questionFormData = {
        ...this.questionForm,
        open_date: convertToUTC(this.questionForm.open_date),
        cutoff_date: convertToUTC(this.questionForm.cutoff_date),
        close_date: convertToUTC(this.questionForm.close_date),
      }
      console.log('questionFormData', questionFormData)

      useJwt.addQuestion(questionFormData)
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('addQuestion', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // Redirect back to questions digest and show notification
          this.$router.push({ name: 'browse-questions' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Question Created',
                  icon: 'QuestionPlusIcon',
                  variant: 'success',
                  text: `You have created a new question: "${this.questionForm.text}"`,
                },
              })
            })
        })
        .catch(err => {
          console.log('AddQuestion ERROR', err.response.status, err.response.statusText, err.response.data)

          const errorResponse = err.response.data.data
          const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

          // Error notification on failure
          this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
